<template>
  <div
    :style="this.$vuetify.theme.dark ? 'background-color: #1E1E1E;' : ''"
    class="card mb-3 main-card"
  >
    <div
      :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
      class="card-header-tab card-header"
    >
      <div
        class="card-header-title font-size-lg text-capitalize font-weight-normal"
      >
        <font-awesome-icon
          :icon="['fal', 'cash-register']"
          class="kassen pr-2"
          size="2x"
        />
        {{ $t("Kitchenmonitors Overview") }}
      </div>
    </div>

    <div class="card-body mh-75 overflow-auto" style="height: 550px">
      <div v-if="!loading" class="row">
        <div
          class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
          v-for="(item, i) in data"
          :key="i"
        >
          <div
            :class="[
              $vuetify.theme.dark ? 'dark-bg' : '',
              'widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-warning card ' +
                border_colors[Math.floor(Math.random() * border_colors.length)],
            ]"
          >
            <div class="widget-chat-wrapper-outer">
              <div class="widget-chart-content">
                <div class="row no-gutters">
                  <div class="col-6">
                    <div class="widget-title opacity-5 text-uppercase">
                      {{ $t("Kitchenmonitor") }} #{{ i }}
                    </div>
                  </div>
                </div>

                <div class="widget-numbers mt-6 mb-0 w-100">
                  <div
                    class="widget-chart-flex font-size-md align-items-center"
                  >
                    <div class="no-gutters row w-100">
                      <div class="col-md-6">
                        <div class="pa-0 card-body">
                          <ul
                            :class="[$vuetify.theme.dark ? 'dark-bg' : 'white']"
                            class="list-group list-group-flush"
                          >
                            <li
                              class="list-group-item pa-0 pl-1 pr-3 transparent"
                            >
                              <div class="widget-content p-0">
                                <div class="widget-content-outer">
                                  <div class="widget-content-wrapper">
                                    <div class="widget-content-left">
                                      <div class="widget-subheading">
                                        {{
                                          $t("Time to finish an order (today)")
                                        }}
                                      </div>
                                    </div>
                                    <div class="widget-content-right">
                                      <div class="widget-numbers text-success">
                                        {{ item.timeToFinishToday }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li
                              class="list-group-item pa-0 pl-1 pr-3 transparent"
                            >
                              <div class="widget-content p-0">
                                <div class="widget-content-outer">
                                  <div class="widget-content-wrapper">
                                    <div class="widget-content-left">
                                      <div class="widget-subheading">
                                        {{ $t("Finished orders (Today)") }}
                                      </div>
                                    </div>
                                    <div class="widget-content-right">
                                      <div class="widget-numbers text-primary">
                                        {{ item.finishedToday }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="pa-0 card-body">
                          <ul
                            :class="[$vuetify.theme.dark ? 'dark-bg' : 'white']"
                            class="list-group list-group-flush"
                          >
                            <li
                              class="list-group-item pa-0 pl-1 pr-3 transparent"
                            >
                              <div class="widget-content p-0">
                                <div class="widget-content-outer">
                                  <div class="widget-content-wrapper">
                                    <div class="widget-content-left">
                                      <div class="widget-subheading">
                                        {{
                                          $t(
                                            "Time to finish an order (yesterday)"
                                          )
                                        }}
                                      </div>
                                    </div>
                                    <div class="widget-content-right">
                                      <div class="widget-numbers text-success">
                                        {{ item.timeToFinishYesterday }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li
                              class="list-group-item pa-0 pl-1 pr-3 transparent"
                            >
                              <div class="widget-content p-0">
                                <div class="widget-content-outer">
                                  <div class="widget-content-wrapper">
                                    <div class="widget-content-left">
                                      <div class="widget-subheading">
                                        {{ $t("Finished orders (Yesterday)") }}
                                      </div>
                                    </div>
                                    <div class="widget-content-right">
                                      <div class="widget-numbers text-primary">
                                        {{ item.finishedYesterday }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="col-12 mt-4" v-if="showTopChart">
                        <div class="pa-0 card-body">
                          <apexchart
                            type="line"
                            height="300"
                            :options="chartOptions"
                            :series="item.series"
                          ></apexchart>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-progress-linear
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
        :active="loading"
      />
    </div>
  </div>
</template>
<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCashRegister } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faCashRegister);
import VueApexCharts from "vue-apexcharts";

var self = null;

export default {
  name: "KassenComponent",
  components: {
    FontAwesomeIcon,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      ENDPOINTS,
      loading: true,
      data: [],
      showTopChart: false,
      chartOptions: {
        chart: {
          stacked: true,
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        stroke: {
          curve: "smooth",
          width: [0, 4],
        },
        dataLabels: {
          enabled: false,
        },
        labels: [
          "00",
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
        ],
        xaxis: {
          title: {
            text: this.$t("generic.lang_hours"),
            type: "time",
          },
        },
        yaxis: [
          {
            title: {
              text: this.$t("generic.lang_orders"),
            },
          },
          {
            opposite: true,
            title: {
              text: this.$t("generic.lang_orderTime"),
            },
          },
        ],
      },
      border_colors: [
        "border-primary",
        "border-success",
        "border-danger",
        "border-info",
        "border-alternate",
        "border-warning",
      ],
    };
  },

  mounted() {
    self = this;

    this.getCashiersReport();
  },

  methods: {
    async getCashiersReport() {
      this.loading = true;

      this.axios
        .get(ENDPOINTS.SETTINGS.KITCHENMONITORS.DASHBOARD.KITCHENMONITOR_STATS)
        .then((res) => {
          if (res.data.success) {
            var data = res.data.data;

            if (Object.values(data).length === 0) {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_noDataToDisplay"),
                color: "warning",
              });
              return;
            }

            Object.values(data).forEach((item) => {
              item.series = [
                {
                  name: this.$t("generic.lang_orders"),
                  type: "column",
                  data: [
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    0, 0, 0, 0,
                  ],
                },
                {
                  name: this.$t("generic.lang_orderTime"),
                  type: "line",
                  data: [
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    0, 0, 0, 0,
                  ],
                },
              ];

              for (const key in item.orderChartToday) {
                item.series[0].data[key] = item.orderChartToday[key];
              }
              for (const key in item.orderTimeChartToday) {
                item.series[1].data[key] = item.orderTimeChartToday[key];
              }
            });

            this.data = data;
            self.showTopChart = true;

            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_dataLoading"),
              color: "success",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
* {
  z-index: 0;
}

.kassen {
  color: #794c8a !important;
}

.card-body {
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.widget-subheading {
  font-size: 0.8rem !important;
}

.widget-numbers .widget-numbers {
  font-size: 0.9rem !important;
}
</style>
